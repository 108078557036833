window.dropDownButtonClicked = (element, multiSelect) => {
	const list = $(element).next()

	if (list.is(":visible")) {
		list.slideUp("fast")
	} else {
		list.slideDown("fast", function () {
			// if the dropdown is a multi-select, don't slide up if the user clicks a dropdown item
			if (multiSelect) {
				$(document).click(function (event) {
					if (
						!$(event.target).hasClass("dropdown-item") &&
						!$(event.target).parents().hasClass("dropdown-item")
					) {
						list.slideUp("fast")
						$(document).prop("onclick", null).off("click")
					}
				})
			} else {
				$(document).one("click", function (event) {
					list.slideUp("fast")
				})
			}
		})
	}
}
